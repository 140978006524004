import React from "react";

type Props = {
    name: string
    onNameChange: (a: string) => void
}

type State = {}

class GroupHeader extends React.Component<Props, State> {

    render() {
        return (
            <div className="card-header">
                <h4
                    className="my-0 fw-normal"
                    contentEditable={true}
                    onBlur={(e) => {this.props.onNameChange(e.target.innerText)}}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            (event.target as HTMLDivElement).blur();
                        }
                    }}
                    suppressContentEditableWarning={true}
                >
                    {this.props.name}
                </h4>
            </div>
        );
    }

}

export default GroupHeader;
