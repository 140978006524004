import React from 'react';
import { makeRequest } from './requests';

type Props = {
    onClose: () => void
}

type State = {}

class Feedback extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.sendFeedback = this.sendFeedback.bind(this);
    }

    async sendFeedback() {
        let feedback = document.getElementById("feedback-text").innerText;
        if (feedback.trim() === "") {
            return;
        }
        let response = await makeRequest({
            action: "Feedback",
            feedback: feedback
        });
        if (response.sent) {
            this.props.onClose();
            alert("Thank you for your feedback, it has been submitted.");
        } else {
            alert("Uh oh. There was an error submitting your feedback, please try again.");
        }
    }

    render() {
        return (
            <div className="popup-container" id="feedback-container">
                <button className="btn" style={{position: "absolute", right: "0px"}} onClick={this.props.onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-square" viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path>
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                    </svg>
                </button>
                <div style={{textAlign: "center", margin: "auto", padding: "5%", height: "100%"}}>
                    <h3>Feedback</h3>
                    <p></p>
                    <div style={{height: "100%"}}>
                        <div contentEditable={true} spellCheck={true} id="feedback-text" style={{border: "1px solid black", width: "100%", margin: "8px", padding: "4px", height: "fit-content"}}></div>
                        <button className="btn btn-outline-secondary" onClick={this.sendFeedback}>Submit</button>
                    </div>
                </div>
            </div>
        );
    }
}

export { Feedback };
