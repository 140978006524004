import { makeRequest } from "./requests";

/**
 * Handle dates used in tasks
 */
class TaskDate {

    static getDateDisplayString(date) {
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        if (month < 10) {
            month = "0" + month;
        }
        let day = date.getDate();
        if (day < 10) {
            day = "0" + day;
        }
        return year + "-" + month + "-" + day;
    }

    /**
     * Set a date to midnight UTC.
     * 
     * @param {Date} date - date to change
     * @returns the date set to midnight UTC for the given day. The day is the
     * day in the users current local time zone from the Date object.
     */
    static setToMidnightUTC(date) {
        // set date to be at midnight local time on the given day
        date = new Date(date.toDateString());
        // adjust to UTC at midnight
        date.setMinutes(0);
        date.setMinutes(-date.getTimezoneOffset());
        return date;
    }

    static dateToISOString(date, hasTime) {
        if (!hasTime) {
            date = TaskDate.setToMidnightUTC(date);
        }
        return date.toISOString();
    }

    // TODO - remove this function
    static validateDate(taskID) {
        let element =  document.getElementById(`${taskID}-date`) as HTMLInputElement;
        let text = element.value;
        text = text.trim();
        if (text === "") {
            element.style.borderColor = "";
            // when the text is empty, remove it
            makeRequest({taskID: taskID, action: "UpdateTask", remove: ["completionDate"]})
            return false;
        }
        // split on a : , / , space, or -
        let elements = text.split(new RegExp("[:/ \-.]+?"));
        let numberElements = [];
        let valid = true;
    
        // convert to ints, invalid if any conversions fail
        for(let i = 0; i < elements.length; i++) {
            numberElements.push(parseInt(elements[i]));
            if (isNaN(numberElements[i])) {
                valid = false;
            }
        }
    
        let currentYear = new Date().getFullYear();
        if (elements.length === 2) {
            let year = currentYear;
            // if the month comes before where we currently are in the year by MORE than 1 month,
            // set it to next year (e.g. making a task in december creates something
            // in Jan of next year)
            if (new Date().getMonth() + 1 > numberElements[0] + 1) {
                year += 1;
            }
            numberElements.push(year);
        } else if (numberElements.length === 3) {
            if (numberElements[2] < currentYear - 10 || numberElements[2] > currentYear + 10) {
                valid = false;
            }
        } else {
            valid = false
        }
    
        let date = null;
        if (valid) {
            // year, month, day
            date = new Date(numberElements[2], numberElements[0]-1, numberElements[1]);
            if (date.getFullYear() !== numberElements[2] || date.getMonth() + 1 !== numberElements[0] || date.getDate() !== numberElements[1]) {
                valid = false;
            }
        }
    
        if (valid) {
            element.style.borderColor = "";
            return date;
        } else {
            element.style.borderColor = "red";
            return null;
        }
    }

    /**
     * Validate a date string
     * @param dateString string representation of date (e.g. 2/22)
     * @returns Date object if valid, or null if invalid
     */
    static validateDateString(dateString: string) {
        if (dateString === "") {
            return null;
        }
        // split on a : , / , space, or -
        let elements = dateString.split(new RegExp("[:/ \-.]+?"));
        let numberElements = [];
        let valid = true;
    
        // convert to ints, invalid if any conversions fail
        for(let i = 0; i < elements.length; i++) {
            numberElements.push(parseInt(elements[i]));
            if (isNaN(numberElements[i])) {
                valid = false;
            }
        }
    
        let currentYear = new Date().getFullYear();
        if (elements.length === 2) {
            let year = currentYear;
            // if the month comes before where we currently are in the year by MORE than 1 month,
            // set it to next year (e.g. making a task in december creates something
            // in Jan of next year)
            if (new Date().getMonth() + 1 > numberElements[0] + 1) {
                year += 1;
            }
            numberElements.push(year);
        } else if (numberElements.length === 3) {
            if (numberElements[2] < currentYear - 10 || numberElements[2] > currentYear + 10) {
                valid = false;
            }
        } else {
            valid = false
        }
    
        let date = null;
        if (valid) {
            // year, month, day
            date = new Date(numberElements[2], numberElements[0]-1, numberElements[1]);
            if (date.getFullYear() !== numberElements[2] || date.getMonth() + 1 !== numberElements[0] || date.getDate() !== numberElements[1]) {
                valid = false;
            }
        }
    
        if (valid && date !== null) {
            return date;
        } else {
            return null;
        }
    }

}

export {TaskDate}
