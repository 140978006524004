import React from 'react';
import { GoogleLogout } from 'react-google-login';
import { About } from './about';
import { Feedback } from './feedback';
import { Settings } from './settings';
import Refresh from './sharedComponents/refresh';
import './style.css';

type Props = {
    isSignedIn: boolean,
    view: string,
    onSignOut: () => void,
    onSetGroupsView: () => void,
    onSetWeeksView: () => void,
    onSetArchivedGroupView: () => void,
    onRefresh: () => void
}

type State = {
    showSettings: boolean,
    showAbout: boolean,
    showFeedback: boolean
}


class Header extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            showSettings: false,
            showAbout: false,
            showFeedback: false
        };
        this.showSettings = this.showSettings.bind(this);
        this.closeSettings = this.closeSettings.bind(this);
        this.showAbout = this.showAbout.bind(this);
        this.closeAbout = this.closeAbout.bind(this);
        this.showFeedback = this.showFeedback.bind(this);
        this.closeFeedback = this.closeFeedback.bind(this);
    }
    
    showSettings() {
        this.setState({showSettings: true});
    }

    closeSettings() {
        this.setState({showSettings: false});
        this.props.onRefresh();
    }

    showAbout() {
        this.setState({showAbout: true});
    }

    closeAbout() {
        this.setState({showAbout: false});
    }

    showFeedback() {
        this.setState({showFeedback: true});
    }

    closeFeedback() {
        this.setState({showFeedback: false});
    }

    render() {
        let groupsStyle = {textDecoration: "none"};
        let weeksStyle = {textDecoration: "none"};
        let archivedGroupsStyle = {textDecoration: "none"};
        let view = this.props.view || "";
        view = view.toLowerCase();
        if (view === "weeks") {
            weeksStyle.textDecoration = "underline";
        } else if (view === "archivedgroups") {
            archivedGroupsStyle.textDecoration = "underline";
        } else if (view === "groups") {
            groupsStyle.textDecoration = "underline";
        }
        return (
            <header className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm">
                <p className="h5 my-0 fw-normal" id="TaskTrack-name">TaskTrack</p>
                    <div className="view-selection" id="view-selection">
                    { this.props.isSignedIn &&
                        <div>
                            <div className="view-selector" id="Group-view-selector" onClick={this.props.onSetGroupsView} style={groupsStyle}>Groups</div>
                            <div className="view-selector" id="Weeks-view-selector" onClick={this.props.onSetWeeksView} style={weeksStyle}>Weeks</div>
                            <div className="view-selector" id="Archived-Groups-view-selector" onClick={this.props.onSetArchivedGroupView} style={archivedGroupsStyle}>Archived Groups</div>
                        </div>
                    }
                    </div>
                <nav className="my-2 my-md-0 mr-md-3">
                    { this.props.isSignedIn &&
                        <button className="btn" onClick={this.props.onRefresh}>
                            <Refresh />
                        </button>
                    }
                    { this.props.isSignedIn &&
                        <button id="settings-button" className="btn" onClick={this.showSettings}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16">
                                <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"></path>
                                <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"></path>
                            </svg>
                        </button>
                    }
                    { this.props.isSignedIn &&
                        <GoogleLogout
                            clientId="962307489782-p2tceqid89rd95rukckp5vl1alus7vrq.apps.googleusercontent.com"
                            buttonText="Logout"
                            onLogoutSuccess={this.props.onSignOut}
                            render={renderProps => (
                                <button className="btn btn-outline-primary btn-header" onClick={renderProps.onClick} disabled={renderProps.disabled}>Sign Out</button>
                              )}
                        />
                    }
                    <button className="btn btn-outline-dark btn-header" onClick={this.showFeedback}>Feedback</button>
                    <button className="btn btn-outline-dark btn-header" onClick={this.showAbout}>About</button>
                </nav>
                { this.state.showSettings &&
                    <Settings onClose={this.closeSettings} onAccountDeletion={this.props.onSignOut}/>
                }
                {
                    this.state.showAbout &&
                    <About onClose={this.closeAbout} />
                }
                {
                    this.state.showFeedback &&
                    <Feedback onClose={this.closeFeedback} />
                }
            </header>
        )
    }
}

export default Header;
