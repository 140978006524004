import React from "react";
import { TaskDate } from "../taskDate";

type Props = {
    date: string
    dateDelimiter: string,
    onDateChange: (a: string) => void
}

type State = {
    validDate: boolean
}


class DateInput extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        let validDate = false;
        if (this.props.date) {
            validDate = this.props.date === new Date(this.props.date).toISOString();
            validDate = validDate || this.props.date === "";
        } else {
            // undefined dates are valid
            validDate = true;
        }
        this.state = {
            validDate: validDate
        }
        this.onDateChange = this.onDateChange.bind(this);
    }

    onDateChange(dateString: string) {
        dateString = dateString.trim();
        if (dateString === "") {
            this.setState({validDate: true});
            this.props.onDateChange(dateString);
            return;
        }
        let date: Date = TaskDate.validateDateString(dateString);
        if (date !== null) {
            this.setState({validDate: true});
            this.props.onDateChange(TaskDate.dateToISOString(date, false));
        } else {
            this.setState({validDate: false});
        }
    }

    get displayText() {
        if (this.props.date) {
            let dateString = this.props.date.split("T")[0];
            let dateParts = dateString.split("-");
            let year = dateParts[0];
            let month = dateParts[1];
            let day = dateParts[2];
            let date = new Date(Number(year), Number(month) -1, Number(day));
            let completionDateText = (date.getMonth()+1) + this.props.dateDelimiter + date.getDate();
            return completionDateText;
        } else {
            return "";
        }
    }

    render() {
        let style: any = {
            width: "90px"
        };
        if (!this.state.validDate) {
            style = {
                width: "90px",
                borderColor: "red"
            };
        }
        return (
            <div className="task-completion-date">
                <input
                    contentEditable={true}
                    type="text"
                    placeholder={"mm" + this.props.dateDelimiter + "dd"}
                    style={style}
                    onBlur={(event) => {this.onDateChange(event.target.value)}}
                    defaultValue={this.displayText}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            (event.target as HTMLElement).blur();
                        }
                    }}
                />
            </div>
        )
    }

}

export default DateInput;
