import React from 'react';

type Props = {
    onClose: () => void
}

type State = {}

class About extends React.Component<Props, State> {

    render() {
        return (
            <div className="popup-container" id="about-container">
                <button className="btn" style={{position: "absolute", right: "0px"}} onClick={this.props.onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-square" viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path>
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                    </svg>
                </button>
                <div style={{textAlign: "center", margin: "auto", padding: "5%"}}>
                    <h3>About</h3>
                    <p></p>
                    <p>This is a site to keep track of tasks. You can view your tasks by groups or weeks.</p>
                    <p>Each task you make has some text, a date, and a completion status. You can enter dates as mm-dd-yyyy or mm-dd. They are always displayed as mm-dd.</p><p>
                    </p><p>Tasks created in weeks view are added to the "ungrouped" group by default. You can choose whether or not to show this group in settings.</p>
                    <p>If you want your data deleted, you can do so using the 'delete account' button in settings.</p><p>
                    </p><p>If you encounter any issues or bugs, please submit them using the "Feedback" form.</p>
                    <p></p>
                </div>
            </div>
        );
    }
}

export { About };
