import React from "react";
import { Group } from "./group/group";
import { getColorFromColorName } from "./home-page";
import { Settings } from "./settings";
import TaskTable from "./taskTableComponent";

type Props = {
    group: Group,
    onNewTask: (a: any) => void,
    disableTaskDragging: boolean
}

type State = {}

class Ungrouped extends React.Component<Props, State> {

    render() {
        let tasks = Group.getSortedTasks(this.props.group);
        return (
            <div className="group-card-container">
                <div className="group-card" style={{backgroundColor: getColorFromColorName(Settings.getDefaultColor())}}>
                    <div className="card-header">
                        <h4 className="my-0 fw-normal">Ungrouped Tasks</h4>
                    </div>
                    <div className="card-body">
                        <TaskTable
                            tasks={tasks}
                            newTask={{
                                groupPosition: tasks.length
                            }}
                            onNewTask={this.props.onNewTask}
                            id={"ungrouped"}
                            disableTaskDragging={this.props.disableTaskDragging}
                        />
                    </div>
                </div>
            </div>                    
        );
    }

}

export default Ungrouped;
