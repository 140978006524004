import React from "react";
import { GoogleLogin } from "react-google-login";
import TaskView from "./taskView";

type Props = {
    onSignIn: (user: any) => void,
    googleUser: any,
    isSignedIn: boolean,
    view: string
}

type State = {}

class Container extends React.Component<Props, State> {

    render() {
        if (!this.props.isSignedIn) {
            return (
                <div id="sign-in-view" className="sign-in-view">
                     <div className="sign-in-box">
                         <h4>Welcome to TaskTrack.</h4>
                         <h5>Sign in to get started.</h5>
                         <GoogleLogin
                            clientId="962307489782-p2tceqid89rd95rukckp5vl1alus7vrq.apps.googleusercontent.com"
                            buttonText="Login"
                            onSuccess={this.props.onSignIn}
                            onFailure={() => {return -1;}}
                            cookiePolicy={'single_host_origin'}
                            isSignedIn={true}
                        />
                     </div>
                 </div>
            )
        } else {
            return (
                <main className="container">
                    <TaskView user={this.props.googleUser} view={this.props.view}/>
                </main>
            )
        }
    }

}

export default Container;
