import React from "react";

type Props = {}

type State = {}

class Loading extends React.Component<Props, State> {

    render() {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status"></div>
            </div>
        )
    }

}

export default Loading;
