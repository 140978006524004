import React from "react";
import GroupsView from "./groupsView";

type Props = {}

type State = {}

class DefaultGroupsView extends React.Component<Props, State> {

    render() {
        return <GroupsView archived={false}/>
    }

}

export { DefaultGroupsView };
