import React from "react";
import { Draggable } from 'react-beautiful-dnd';
import { getColorFromColorName } from "../home-page";
import { makeRequest } from "../requests";
import Task from "../task/task";
import TaskTable from "../taskTableComponent";
import GroupFooter from "./groupFooter";
import GroupHeader from "./groupHeader";

type Props = {
    groupId: string,
    name: string,
    position: number,
    color: string,
    showCompleted: boolean,
    isArchived: boolean,
    tasks: Task[],
    onNewTask: (a: any) => void,
    onArchive: (groupId: any, isArchived: boolean) => void,
    onDeletion: (groupId: any) => void,
    onGroupChange: (group: Group) => void,
    disableTaskDragging?: boolean,
    index?: number
}

type State = {}

type GroupUpdate ={
    name?: string,
    position?: string,
    color?: string,
    showCompleted?: boolean
}

class Group extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.onNewTask = this.onNewTask.bind(this);
        this.onArchive = this.onArchive.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onShowCompletedChange = this.onShowCompletedChange.bind(this);
        this.onColorChange = this.onColorChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    onNewTask(newTask: any) {
        this.props.onNewTask(newTask);
    }

    onNameChange(name: string) {
        this.updateGroup({name: name});
    }

    onShowCompletedChange(showCompleted: boolean) {
        this.updateGroup({showCompleted: showCompleted});
    }

    onColorChange(color: string) {
        this.updateGroup({color: color});
    }

    async updateGroup(groupUpdate: GroupUpdate) {
        let update = groupUpdate as any;
        update.groupID = this.props.groupId;
        update.action = "UpdateGroup";
        let groupJson = await makeRequest(update);
        let group = this.fromUpdatedJson(groupJson);
        this.props.onGroupChange(group);
    }

    async onDelete() {
        let toDelete = window.confirm(`Are you sure you want to delete the group '${this.props.name}'? This will delete the group and all associated tasks.`);
        if (!toDelete) {
            return;
        }
        let response = await makeRequest({action: "DeleteGroup", groupID: this.props.groupId});
        if (response.deleted) {
            this.props.onDeletion(this.props.groupId);
        }
    }

    async onArchive() {
        let groupJson = await makeRequest({
            action: "UpdateGroup",
            groupID: this.props.groupId,
            isArchived: !this.props.isArchived
        });
        this.props.onArchive(groupJson.groupID, groupJson.isArchived);
    }

    fromUpdatedJson(groupJson): Group {
        return (<Group
            groupId={this.props.groupId}
            name={groupJson.name}
            position={groupJson.position}
            color={groupJson.color}
            showCompleted={groupJson.showCompleted}
            isArchived={groupJson.isArchived}
            tasks={this.props.tasks}
            onNewTask={this.props.onNewTask}
            onArchive={this.props.onArchive}
            onDeletion={this.props.onDeletion}
            onGroupChange={this.props.onGroupChange}
            /> as unknown as Group);
    }

    render() {
        let sortedTasks: Task[] = Group.getSortedTasks(this);
        return (
            <Draggable draggableId={this.props.groupId} index={this.props.index}>
                {provided => (
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                    >
                        <div className="group-card-container">
                            <div className="group-card" style={{backgroundColor: getColorFromColorName(this.props.color)}}>
                                <GroupHeader
                                    name={this.props.name}
                                    onNameChange={this.onNameChange}
                                />
                                <div className="card-body">
                                    <TaskTable 
                                        tasks={sortedTasks}
                                        newTask={{
                                            groupId: this.props.groupId,
                                            groupPosition: (this.props.tasks.length || 0)
                                        }}
                                        onNewTask={this.onNewTask}
                                        id={this.props.groupId}
                                        disableTaskDragging={this.props.disableTaskDragging}
                                    />
                                    <GroupFooter
                                        showCompleted={this.props.showCompleted}
                                        color={this.props.color}
                                        onShowCompletedChange={this.onShowCompletedChange}
                                        onColorChange={this.onColorChange}
                                        onDelete={this.onDelete}
                                        onArchive={this.onArchive}    
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Draggable>
            
        );
    }

    static containsTask(group: Group, taskId: string) {
        for (let i = 0; i < group.props.tasks.length; i++) {
            if (group.props.tasks[i].props.id === taskId) {
                return true;
            }
        }
        return false;
    }

    static getSortedTasks(group: Group): Task[] {
        let sortedTasks = [...group.props.tasks];
        sortedTasks.sort((a: Task, b: Task) => {
            // either both complete or both incomplete
            // sort on groupPosition
            if ((a.props.isComplete && b.props.isComplete) || (!a.props.isComplete && !b.props.isComplete)) {
                return a.props.groupPosition - b.props.groupPosition;
            } else {
                // one complete, the other is not
                // complete task comes first
                return a.props.isComplete ? 1 : -1;
            }
        });
        // set task colors here, to ensure they align with the group
        for (let i = 0; i < sortedTasks.length; i++) {
            sortedTasks[i] = <Task {...sortedTasks[i].props} color={group.props.color}/> as unknown as Task;
        }
        // filter based on showCompleted
        if (!group.props.showCompleted) {
            sortedTasks = sortedTasks.filter(t => !t.props.isComplete);
        }
        return sortedTasks;
    }

    /**
     * Create a new Group with the task added
     */
    static addTask(group: Group, task: Task) : Group {
        let tasks = [...group.props.tasks];
        tasks.push(task);
        let newProps = {...group.props};
        newProps.tasks = tasks;
        return (<Group
            groupId = {newProps.groupId}
            name = {newProps.name}
            position = {newProps.position}
            color = {newProps.color}
            showCompleted = {newProps.showCompleted}
            isArchived = {newProps.isArchived}
            tasks = {newProps.tasks}
            onNewTask = {newProps.onNewTask}
            onGroupChange = {newProps.onGroupChange}
            onArchive = {newProps.onArchive}
            onDeletion = {newProps.onDeletion}
            /> as unknown as Group);
    }

    /**
     * Return a new Group, without the specified task
     */
    static removeTask(group: Group, taskId: string) : Group {
        let tasks = [...group.props.tasks];
        for(let i = 0; i < tasks.length; i++) {
            if (tasks[i].props.id === taskId) {
                tasks.splice(i, 1);
            }
        }
        let newProps = {...group.props};
        newProps.tasks = tasks;
        return (<Group
            groupId = {newProps.groupId}
            name = {newProps.name}
            position = {newProps.position}
            color = {newProps.color}
            showCompleted = {newProps.showCompleted}
            isArchived = {newProps.isArchived}
            tasks = {newProps.tasks}
            onNewTask = {newProps.onNewTask}
            onGroupChange = {newProps.onGroupChange}
            onArchive = {newProps.onArchive}
            onDeletion = {newProps.onDeletion}
            /> as unknown as Group);
    }

    static fromJson(groupJson, tasks, onNewTask, onArchive, onDeletion, onGroupChange) : Group {
        return (<Group
            groupId={groupJson.groupID}
            name={groupJson.name}
            position={groupJson.position}
            color={groupJson.color}
            showCompleted={groupJson.showCompleted}
            isArchived={groupJson.isArchived}
            tasks={tasks}
            onNewTask={onNewTask}
            onArchive={onArchive}
            onDeletion={onDeletion}
            onGroupChange={onGroupChange}
            /> as unknown as Group);
    }

}

export { Group };
