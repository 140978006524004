import { runInThisContext } from "node:vm";
import React from "react";

type Props = {
    text: string
    onTextChange: (a: string) => void
}

type State = {}


class TextInput extends React.Component<Props, State> {

    onTextChange(text: string) {
        this.props.onTextChange(text);
    }

    render() {
        return (
            <div className="task-textbox">
                <div
                    contentEditable={true}
                    spellCheck={true}
                    onBlur={(event) => this.onTextChange((event.target as HTMLDivElement).innerText)}
                    suppressContentEditableWarning={true}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            (event.target as HTMLDivElement).blur();
                        }
                    }}
                    >
                    {this.props.text}
                </div>
            </div>
        )
    }

}

export default TextInput;
