import { Settings } from "./settings";

// blur an element on enter
function addBlurOnEnter(element) {
    element.addEventListener('keypress', function(e) {
        if (e.which === 13) {
            e.preventDefault();
            this.blur();
        }
    });
}

function getColorFromColorName(colorName) {
    switch (colorName) {
        case "red":
            return "#FF5733";
        case "orange":
            return "#fdad5c";
        case "yellow":
            return "#f6da40";
        case "green":
            return "#75FF33";
        case "blue":
            return "#81d4fa";
        case "grey":
            return "#a8a8a8";
        case "white":
            return "#fff";
        default:
            return getColorFromColorName(Settings.getDefaultColor());
    }
}

function getViewSelector() : HTMLSelectElement {
    return document.getElementById("viewType") as HTMLSelectElement;
}

function getGroupViewSelector() : HTMLElement {
    return document.getElementById("Group-view-selector");
}

function getWeekViewSelector() : HTMLElement {
    return document.getElementById("Weeks-view-selector");
}

function getArchivedViewSelector() : HTMLElement {
    return document.getElementById("Archived-Groups-view-selector");
}

// get the current view
function getView() : string {
    let groupSelector = getGroupViewSelector();
    let weekSelector = getWeekViewSelector();
    let archivedSelector = getArchivedViewSelector();
    function isActive(selector: HTMLElement) {
        return selector.style.textDecoration === "underline";
    }
    if (isActive(groupSelector)) {
        return "groups";
    } else if (isActive(weekSelector)) {
        return "weeks";
    } else if (isActive(archivedSelector)) {
        return "archivedGroups";
    } else {
        console.log("Group is not set");
    }
}

export {addBlurOnEnter, getView, getColorFromColorName}
