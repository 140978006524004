import React from "react";
import { Draggable } from 'react-beautiful-dnd';
import { getColorFromColorName } from "../home-page";
import { Settings } from "../settings";
import Checkbox from "./checkbox";
import DateInput from "./dateInput";
import DeleteButton from "./deleteButton";
import DragHandle from "./dragHandle";
import TextInput from "./textInput";
import GroupSelector from "./groupSelector";
import { Group } from "../group/group";
import { makeRequest } from "../requests";

type Props = {
    onUpdate: (t: Task) => void,
    onDelete: (a: string) => void,
    text: string,
    id: string,
    isComplete: boolean,
    groupId: string,
    renderPosition: number,
    groupPosition?: number,
    dayPosition?: number,
    completionDate?: string,
    completionDateHasTime?: boolean,
    workDate?: string,
    workDateHasTime?: boolean,
    color?: string,
    groups?: Group[]
}

type State = {}


class Task extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.onTextChange = this.onTextChange.bind(this);
        this.onIsCompleteChange = this.onIsCompleteChange.bind(this);
        this.onGroupIdChange = this.onGroupIdChange.bind(this);
        this.onGroupPositionChange = this.onGroupPositionChange.bind(this);
        this.onDayPositionChange = this.onDayPositionChange.bind(this);
        this.onCompletionDateChange = this.onCompletionDateChange.bind(this);
        this.onCompletionDateHasTimeChange = this.onCompletionDateHasTimeChange.bind(this);
        this.onWorkDateChange = this.onWorkDateChange.bind(this);
        this.onWorkDateHasTimeChange = this.onWorkDateHasTimeChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.updateTask = this.updateTask.bind(this);
    }

    onTextChange(text: string) {
        this.updateTask({Task: text});
    }

    onIsCompleteChange(isComplete: boolean) {
        this.updateTask({IsComplete: isComplete});
    }

    onGroupIdChange(groupId: string) {
        this.updateTask({Group: groupId});
    }

    onGroupPositionChange(groupPosition: number) {
        this.updateTask({groupPosition: groupPosition});
    }

    onDayPositionChange(dayPosition: number) {
        this.updateTask({dayPosition: dayPosition});
    }

    async onCompletionDateChange(isoDateString: string) {
        console.log(this.props);
        if (isoDateString === "") {
            let taskJson = await makeRequest({taskID: this.props.id, action: "UpdateTask", remove: ["completionDate"]});
            console.log(this.props);
            this.props.onUpdate(Task.createTaskFromJson(taskJson, this.props.renderPosition));
        } else {
            this.updateTask({completionDate: isoDateString});
        }
    }

    onCompletionDateHasTimeChange(hasTime: boolean) {
        this.updateTask({completionDateHasTime: hasTime});
    }
    
    onWorkDateChange(dateString: string) {
        // TODO
    }

    onWorkDateHasTimeChange(hasTime: boolean) {
        let props = {...this.props};
        props.workDateHasTime = hasTime;
        this.props.onUpdate(new Task(props));
    }

    async onDelete() {
        let response = await makeRequest({action: "DeleteTask", taskID: this.props.id});
        if (response.deleted !== true) {
            console.log("Failed to delete task. Response: " + response);
            return;
        }
        this.props.onDelete(this.props.id);
    }

    async updateTask(update) {
        let updatedJson = await makeRequest({
            action: "UpdateTask",
            taskID: this.props.id,
            update: update
        });
        let task = Task.createTaskFromJson(
            updatedJson,
            this.props.renderPosition,
            this.props.onUpdate,
            this.props.onDelete,
            this.props.groups,
            this.props.color
        );
        console.log(this.props);
        this.props.onUpdate(task);
    }

    render() {
        // TODO - add Group selector
        // TODO - make the index based on group OR day depending on view
        return (
            <Draggable draggableId={this.props.id} index={this.props.renderPosition}>
                {provided => (
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                    >
                        <div className="task-row" style={{backgroundColor: getColorFromColorName(this.props.color)}}>
                            <DragHandle />
                            <Checkbox checked={this.props.isComplete} onCheck={this.onIsCompleteChange}/>
                            <TextInput text={this.props.text} onTextChange={this.onTextChange}/>
                            <DateInput
                                date={this.props.completionDate}
                                dateDelimiter={Settings.getDateDelimiter()}
                                onDateChange={this.onCompletionDateChange}
                            />
                            {this.props.groups !== undefined &&
                                <GroupSelector
                                    groups={this.props.groups}
                                    groupId={this.props.groupId}
                                    onGroupChange={this.onGroupIdChange}
                                />
                            }
                            <DeleteButton onClick={() => this.onDelete()}/>
                        </div>
                    </div>
                )}
            </Draggable>
        )
    }

    static createTaskFromJson(taskJson,
        renderPosition: number,
        onUpdate?: (t: Task) => void,
        onDelete?: (a: string) => void,
        groups?: Group[],
        color?: string): Task {
        // override color argument if it is in the json or in a group definition
        if (taskJson.color) {
            color = taskJson.color;
        } else if (groups) {
            let groupId = taskJson.Group;
            for (let i=0; i < groups.length; i++) {
                if (groups[i].props.groupId === groupId) {
                    color = groups[i].props.color;
                    break;
                }
            }
        }
        return (<Task
            onUpdate={onUpdate}
            onDelete={onDelete}
            text={taskJson.Task}
            id={taskJson.TaskID}
            key={taskJson.TaskID}
            groupId={taskJson.Group}
            isComplete={taskJson.IsComplete}
            renderPosition={renderPosition}
            groupPosition={taskJson.groupPosition}
            dayPosition={taskJson.dayPosition}
            workDate={taskJson.workDate}
            workDateHasTime={taskJson.workDateHasTime}
            completionDate={taskJson.completionDate}
            completionDateHasTime={taskJson.completionDateHasTime}
            color={color}
            groups={groups}
        /> as unknown as Task);
    }

}

export default Task;
