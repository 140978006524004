import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { DragDropContext } from 'react-beautiful-dnd';
import Task from "./task/task";
import { TaskDate } from "./taskDate";
import TaskTable from "./taskTableComponent";
import Loading from "./sharedComponents/loading";
import { Group } from "./group/group";
import { makeRequest } from "./requests";

type Props = {}

type State = {
    date: Date
}

type CalendarProps = {
    date: Date
    onChange: (a: Date) => void
}

type CalendarState = {}

type WeekTableProps = {
    date: Date
}

type WeekTableState = {
    loading: boolean,
    groups: Group[],
    tasks: Task[]
}

type DayContainerProps = {
    date: Date,
    adjustedDate: Date,
    tasks: Task[],
    onNewTask: (a: any) => any
}

type DayContainerState = {}


class DayContainer extends React.Component<DayContainerProps, DayContainerState> {

    constructor(props) {
        super(props);
        this.onNewTask = this.onNewTask.bind(this);
    }

    onNewTask(newTask) {
        this.props.onNewTask(newTask);
    }

    render() {
        let sortedTasks = [...this.props.tasks];
        sortedTasks.sort((a: Task, b: Task) => {
            // either both complete or both incomplete
            // sort on groupPosition
            if ((a.props.isComplete && b.props.isComplete) || (!a.props.isComplete && !b.props.isComplete)) {
                return a.props.dayPosition - b.props.dayPosition;
            } else {
                // one complete, the other is not
                // complete task comes first
                return a.props.isComplete ? 1 : -1;
            }
        });
        return (
            <div className="day-container">
                <div className="day-header">{this.props.date.toDateString()}</div>
                <TaskTable
                    tasks={sortedTasks}
                    newTask={ {completionDate: this.props.adjustedDate.toISOString(), groupId: "ungrouped", dayPosition: (sortedTasks.length || 0)}}
                    onNewTask={this.onNewTask}
                    id={this.props.adjustedDate.toISOString()}
                />
            </div>
        );
    }
}

class WeekTable extends React.Component<WeekTableProps, WeekTableState> {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            groups: [],
            tasks: []
        };
        this.onNewTask = this.onNewTask.bind(this);
        this.onTaskUpdate = this.onTaskUpdate.bind(this);
        this.onTaskDelete = this.onTaskDelete.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {
        this.fetchWeek();
    }

    componentDidUpdate(previousProps: WeekTableProps) {
        if (previousProps.date !== this.props.date) {
            this.fetchWeek();
        }
    }

    onDragEnd(result) {
        if (!result.destination) {
            // we dragged the task out of range, ignore the drag
            return;
        }
        let destinationDate = result.destination.droppableId;
        let sourceDate = result.source.droppableId;
        let destinationIndex = result.destination.index;
        let sourceIndex = result.source.index;
        let taskId = result.draggableId;
        let tasks = [...this.state.tasks];
        let taskUpdates = [];
        for (let i = 0; i < tasks.length; i++) {
            let task = tasks[i];
            if (task.props.id === taskId) {
                // update the date and index
                task = <Task
                    {...task.props}
                    dayPosition={destinationIndex}
                    renderPosition={destinationIndex}
                    completionDate={destinationDate}
                /> as unknown as Task;
                taskUpdates.push({
                    taskID: task.props.id,
                    update: {
                        dayPosition: task.props.dayPosition,
                        completionDate: task.props.completionDate
                    }
                });
            } else if (task.props.completionDate === destinationDate) {
                let updatedPosition = task.props.dayPosition;
                if (task.props.dayPosition > destinationIndex) {
                    updatedPosition += 1;
                } else if (task.props.dayPosition == destinationIndex) {
                    // determine if it moved up or down
                    if (sourceDate != destinationDate) {
                        updatedPosition = task.props.dayPosition + 1;
                    } else if (sourceIndex > destinationIndex) {
                        updatedPosition = task.props.dayPosition + 1;
                    } else {
                        updatedPosition = task.props.dayPosition - 1;
                    }
                }
                task = <Task
                    {...task.props}
                    dayPosition={updatedPosition}
                    renderPosition={updatedPosition}
                /> as unknown as Task;
                taskUpdates.push({
                    taskID: task.props.id,
                    update: {
                        dayPosition: task.props.dayPosition,
                    }
                });
            }
            tasks[i] = task;
        }
        this.setState({tasks: tasks});
        makeRequest({action: "BatchUpdateTask", taskUpdates: taskUpdates});
    }

    render() {
        if (this.state.loading) {
            return (
                <Loading />
            );
        } else {
            // iterate days of the week
            let dayContainers = [];
            let date = new Date(this.props.date.getTime());
            let adjustedDate = TaskDate.setToMidnightUTC(date);
            for(let i = 0; i < 7; i ++) {
                let dayTasks = this.state.tasks.filter(task => {
                    return task.props.completionDate === adjustedDate.toISOString();
                });
                dayContainers.push(
                    <DayContainer
                        date={new Date(date.getTime())}
                        adjustedDate={new Date(adjustedDate.getTime())}
                        tasks={dayTasks}
                        onNewTask={this.onNewTask}
                        key={i}
                    />
                );
                date.setDate(date.getDate() + 1);
                // recreate the adjusted date from the date each time
                // this prevents daylight savings time from breaking our code
                // since incrementing the day would cause it to be off by 1 hour
                adjustedDate = TaskDate.setToMidnightUTC(date);
            }
            return (
                <div className="task-week-table">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        { dayContainers }
                    </DragDropContext>
                </div>
            );
        }
        
    }

    async onNewTask(newTaskJson) {
        let tasks = this.state.tasks.concat(this.createTaskFromJson(newTaskJson, this.state.tasks.length, this.state.groups));
        this.setState({tasks: tasks});
        console.log(this.state.tasks.length);
    }

    async onTaskDelete(taskId: string) {
        // remove task from state
        let tasks = this.state.tasks.filter(
            task => task.props.id !== taskId
        );
        this.setState({tasks: tasks});
        // TODO - make undo delete button
    }

    async fetchWeek() {
        this.setState({loading: true});
        let adjustedDate = TaskDate.setToMidnightUTC(this.props.date);
        let endDate = new Date(adjustedDate);
        endDate.setDate(endDate.getDate() + 6);
        // Handle Daylight savings
        // this makes us behind or ahead by 1 hour
        // the easy way to handle this is to increment the hour by 1
        // worst case, we grab an extra hour in the query (which will be empty anyways)
        endDate.setHours(endDate.getHours() + 1);
        let weeksView = await makeRequest({
            action: "FetchTasks",
            viewType: "dates",
            startDate: adjustedDate.toISOString(),
            endDate: endDate.toISOString()
        });
        let groups = weeksView.groups;
        for (let i = 0; i < groups.length; i++) {
            groups[i] = Group.fromJson(groups[i], [], undefined, undefined, undefined, undefined);
        }
        this.setState({groups: groups});
        let tasks = weeksView.tasks;
        for (let i = 0; i < tasks.length; i++) {
            tasks[i] = this.createTaskFromJson(tasks[i], i, groups);
        }
        this.setState({tasks: tasks});
        this.setState({groups: groups});
        this.setState({loading: false});
    }

    createTaskFromJson(taskJson, renderPosition: number, groups?: Group[]) : Task {
        return Task.createTaskFromJson(taskJson,
            renderPosition,
            this.onTaskUpdate,
            this.onTaskDelete,
            groups
        );
    }

    async onTaskUpdate(newTask: Task) {
        this.replaceTask(newTask);
    }

    replaceTask(newTask: Task) {
        // Update that tasks array with the new task
        let tasks = [...this.state.tasks];
        for (let i=0; i < tasks.length; i++) {
            if (tasks[i].props.id === newTask.props.id) {
                tasks[i] = newTask;
                break;
            }
        }
        this.setState({tasks: tasks});
    }

}


class CalendarSelection extends React.Component<CalendarProps, CalendarState> {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <button className="btn btn-light" style={{float: "left"}} onClick= {() => this.props.onChange(this.getPreviousDate())}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-square" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                    </svg>
                    Previous Week
                </button>
                <button className="btn btn-light" style={{float: "right"}} onClick= {() => this.props.onChange(this.getNextDate())}>Next Week
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-square" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm4.5 5.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                    </svg>
                </button>
                <div className="date-jump-input-container">
                    <DatePicker selected={this.props.date} onChange={(date: Date) => this.props.onChange(date)} />
                </div>
            </div>
        );
    }

    private getPreviousDate() {
        let previousDate = new Date(this.props.date);
        previousDate.setDate(this.props.date.getDate() - 7);
        return previousDate;
    }

    private getNextDate() {
        let nextDate = new Date(this.props.date);
        nextDate.setDate(this.props.date.getDate() + 7);
        return nextDate;
    }
}

class WeeksView extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        let date = new Date();
        // set date to the last sunday (start of the week)
        date.setDate(date.getDate() - date.getDay());
        date.setMinutes(0);

        this.state = {
            date: date
        }
        this.onDateChange = this.onDateChange.bind(this)
    }

    render() {
        return (
            <div>
                <CalendarSelection date={ this.state.date } onChange={ this.onDateChange }/>
                <WeekTable date={ this.state.date }/>
            </div> 
        )
    }

    onDateChange(date: Date) {
        date.setDate(date.getDate() - date.getDay());
        date.setMinutes(0);
        this.setState({date: date});
    }

}

export default WeeksView;
