import React from "react"
import { Group } from "../group/group";

type Props ={
    groupId: string,
    groups: Group[],
    onGroupChange: (id: string) => void
}

type State ={}

class GroupSelector extends React.Component<Props, State> {

    onGroupChange(groupId: string) {
        this.props.onGroupChange(groupId);
    }

    render() {
        let options = [];
        for (let i=0; i < this.props.groups.length; i++) {
            let displayName = this.props.groups[i].props.name;
            if (displayName.length > 20) {
                displayName = displayName.slice(0, 18) + "...";
            }
            options.push(
                <option value={this.props.groups[i].props.groupId} key={this.props.groups[i].props.groupId}>
                    {displayName}
                </option>
            )
        }
        return (
            <div className="task-group-selector">
                <select
                    value={this.props.groupId}
                    onChange={(e) => {this.onGroupChange(e.target.value)}}>
                    {options}
                </select>
            </div>
        );
    }

}

export default GroupSelector;
