import React from "react";
import { Droppable } from 'react-beautiful-dnd';
import { makeRequest } from "./requests";
import Task from "./task/task";

type NewTask = {
    text?: string,
    groupId?: string,
    groupPosition?: number,
    dayPosition?: number,
    completionDate?: string,
    completionDateHasTime?: boolean,
    workDate?: string,
    workDateHasTime?: boolean
}

type Props = {
    id: string,
    tasks: Task[],
    newTask: NewTask,
    onNewTask: (a: any) => any,
    disableTaskDragging?: boolean
}

type State = {}

class TaskTable extends React.Component<Props, State> {

    async onNewTask(text: string) {
        if (text.trim() === "") {
            return;
        }
        let newTask: NewTask = {text: text};
        if (this.props.newTask.groupId !== undefined) {
            newTask.groupId = this.props.newTask.groupId;
        }
        if (this.props.newTask.groupPosition !== undefined) {
            newTask.groupPosition = this.props.newTask.groupPosition;
        }
        if (this.props.newTask.dayPosition !== undefined) {
            newTask.dayPosition = this.props.newTask.dayPosition;
        }
        if (this.props.newTask.completionDate !== undefined) {
            newTask.completionDate = this.props.newTask.completionDate;
        }
        if (this.props.newTask.completionDateHasTime !== undefined) {
            newTask.completionDateHasTime = this.props.newTask.completionDateHasTime;
        }
        if (this.props.newTask.workDate !== undefined) {
            newTask.completionDateHasTime = this.props.newTask.completionDateHasTime;
        }
        if (this.props.newTask.workDateHasTime !== undefined) {
            newTask.completionDateHasTime = this.props.newTask.completionDateHasTime;
        }

        console.log("New task: " + newTask.text + " " + newTask.completionDate);        
        let taskJson: any = {Task: newTask.text};
        // TODO - add other JSON fields in here
        if (newTask.groupId !== undefined) {
            taskJson.Group = newTask.groupId;
        }
        if (newTask.groupPosition !== undefined) {
            taskJson.groupPosition = newTask.groupPosition;
        }
        if (newTask.dayPosition !== undefined) {
            taskJson.dayPosition = newTask.dayPosition;
        }
        if (newTask.completionDate !== undefined) {
            taskJson.completionDate = newTask.completionDate;
        }
        if (newTask.workDate !== undefined) {
            taskJson.workDate = newTask.workDate;
        }
        let newTaskJson = await makeRequest({action: "AddTask", task: taskJson});
        this.props.onNewTask(newTaskJson);
    }

    render() {
        // reset indexes on task to ensure they are consecutive
        // react-beatiful-dnd requires these are consecutive
        let tasks = [...this.props.tasks];
        for (let i = 0; i < tasks.length; i++) {
            tasks[i] = <Task {...tasks[i].props} renderPosition={i} key={tasks[i].props.id} /> as unknown as Task;
        }
        return (
            <div className="task-table">
                <Droppable droppableId={this.props.id} isDropDisabled={this.props.disableTaskDragging}>
                    {provided => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {tasks}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                <div className="create-task-row">
                    <div
                        className="create-task-textbox"
                        contentEditable={true}
                        spellCheck={true}
                        onBlur={(event) => {this.onNewTask(event.target.innerText);
                                            event.target.innerText = "";}}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                (event.target as HTMLDivElement).blur();
                            }
                        }}
                    />
                </div>
            </div>
            
        )
    }

}

export default TaskTable;
