import React from 'react';
import Container from './container';
import Header from './header';
import { onSignOut, setGoogleUser } from './login';
import { getAPIUrl } from './requests';
import { Settings } from './settings';
import './style.css';

type Props = {}

type State = {
    isSignedIn: boolean
    googleUser: any,
    view: string
}

class App extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            isSignedIn: false,
            googleUser: null,
            view: null
        }
        this.onSignIn = this.onSignIn.bind(this);
        this.onSignOut = this.onSignOut.bind(this);
        this.onSetGroupsView = this.onSetGroupsView.bind(this);
        this.onSetWeeksView = this.onSetWeeksView.bind(this);
        this.onSetArchivedGroupsView = this.onSetArchivedGroupsView.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    onSignIn(user) {
        this.setState({googleUser: user});
        this.setState({isSignedIn: true});
        var profile = this.state.googleUser.getBasicProfile();
        // TODO - we should probably pass the user around rather
        // than set it
        setGoogleUser(user);
        console.log("Signed in as " + profile.getName());
        // TODO - sign up conversion event
        // gtag('event', 'conversion', {'send_to': 'AW-418091182/6Ch-CMmkpPYBEK6hrscB'});
        // fetch settings, which will set the view
        this.fetchSettings();
    }

    onSignOut() {
        console.log("Signing out!");
        onSignOut();
        this.setState({googleUser: null});
        this.setState({isSignedIn: false});
    }

    onSetGroupsView() {
        this.setState({view: "groups"});
    }

    onSetWeeksView() {
        this.setState({view: "weeks"});
    }

    onSetArchivedGroupsView() {
        this.setState({view: "archivedGroups"});
    }

    refresh() {
        // set view to null, then immedeately reset it
        // this forces our components to reload, and ensures
        // changes to settings get displayed
        let resetView = (view: string) => {
            this.setState({view: view});
        }
        let view = this.state.view;
        this.setState({view: null});
        setTimeout(() => resetView(view), 1);
    }

    render() {
        return (
            <div>
                <Header
                    isSignedIn={this.state.isSignedIn}
                    onSignOut={this.onSignOut}
                    onSetGroupsView={this.onSetGroupsView}
                    onSetWeeksView={this.onSetWeeksView}
                    onSetArchivedGroupView={this.onSetArchivedGroupsView}
                    view={this.state.view}
                    onRefresh={this.refresh}
                />
                <Container
                    onSignIn={this.onSignIn}
                    googleUser={this.state.googleUser}
                    isSignedIn={this.state.isSignedIn}
                    view={this.state.view}
                />
            </div>
        );
    }

    async fetchSettings() {
        fetch(getAPIUrl(),
            {
                method: "POST",
                body: JSON.stringify(
                    {idToken: this.state.googleUser.getAuthResponse().id_token,
                    action: "Login"}),
                headers:{ "Content-Type": "application/json"}
            }
        ).then(response => response.json())
        .then(data => {
            console.log("Response from sign in: " + data);
            Settings.setSettings(data.settings);
            // We have now loaded the settings
            // set loading as false which will trigger redering of default view
            this.setState({view: Settings.getDefaultView()});
        });
    }

}

export default App;
