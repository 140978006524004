import { getAPIUrl } from "./requests";
import { Settings } from "./settings";

var googleUser;

// declare google functions to satisfy TypeScript compiler
declare var gapi : any;
declare var gtag: any;

function setGoogleUser(user) {
    googleUser = user;
}

function getGoogleUser() {
    return googleUser;
}

function onSignIn(user) {
    googleUser = user;
    var profile = googleUser.getBasicProfile();
    console.log("Signed in as " + profile.getName());
    // sign up conversion event
    gtag('event', 'conversion', {'send_to': 'AW-418091182/6Ch-CMmkpPYBEK6hrscB'});

    fetch(getAPIUrl(),
            {
                method: "POST",
                body: JSON.stringify(
                    {idToken: googleUser.getAuthResponse().id_token,
                    action: "Login"}),
                headers:{ "Content-Type": "application/json"}
            }
        ).then(response => response.json())
        .then(data => {
            console.log("Response from sign in: " + data);
            Settings.setSettings(data.settings);
        });
  }

function onSignOut() {
    setGoogleUser(undefined);
    var auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut()
}

export {setGoogleUser, getGoogleUser, onSignIn, onSignOut}
