import React from "react";

type Props = {
    showCompleted: boolean,
    color: string,
    onShowCompletedChange: (a: boolean) => void,
    onColorChange: (a: string) => void
}

type State = {
    showDropdown: boolean
}

class DropdownSettings extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            showDropdown: false
        };
        this.onShowDropDownClick = this.onShowDropDownClick.bind(this);
    }

    onShowDropDownClick() {
        this.setState({showDropdown: !this.state.showDropdown});
    }

    render() {
        return (
            <div className="group-settings-button">
                <button className="btn" type="button" onClick={this.onShowDropDownClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots" viewBox="0 0 16 16">
                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
                    </svg>
                </button>
                {this.state.showDropdown &&

                <div className="dropdown-content">
                    <div className="task-checkbox">
                        <label>Show completed tasks:</label>
                        <input
                            className="form-check-input position-static"
                            type="checkbox"
                            checked={this.props.showCompleted}
                            onChange={(e) => this.props.onShowCompletedChange(e.target.checked)}
                        />
                    </div>
                    <div className="group-change-color">
                        <label>Color: </label>
                        <select value={this.props.color} onChange={e => this.props.onColorChange(e.target.value)}>
                            <option value="default">default</option>
                            <option value="red">red</option>
                            <option value="orange">orange</option>
                            <option value="yellow">yellow</option>
                            <option value="green">green</option>
                            <option value="blue">blue</option>
                            <option value="grey">grey</option>
                            <option value="white">white</option>
                        </select>
                    </div>
                </div>
                }
                
            </div>
        );
    }

}

export default DropdownSettings;
