import React from "react";
import GroupsView from "./groupsView";

type Props = {}

type State = {}

class ArchivedGroupsView extends React.Component<Props, State> {

    render() {
        return <GroupsView archived={true}/>
    }

}

export { ArchivedGroupsView };
