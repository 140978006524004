import React from "react";
import ArchiveButton from "./archiveButton";
import DeletionButton from "./deletionButton";
import DropdownSettings from "./dropdownSettings";

type Props = {
    showCompleted: boolean,
    color: string,
    onShowCompletedChange: (a: boolean) => void,
    onColorChange: (a: string) => void,
    onDelete: () => void,
    onArchive: () => void
}

type State = {}

class GroupFooter extends React.Component<Props, State> {

    render() {
        return (
            <div className="task-row group-settings-row">
                <div className="group-drag-element" draggable="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-grip-vertical" viewBox="0 0 16 16">
                        <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                    </svg>
                </div>
                <ArchiveButton onArchive={this.props.onArchive} />
                <DeletionButton onDelete={this.props.onDelete} />
                <DropdownSettings
                    showCompleted={this.props.showCompleted}
                    color={this.props.color}
                    onColorChange={this.props.onColorChange}
                    onShowCompletedChange={this.props.onShowCompletedChange} 
                />
            </div>
        );
    }

}

export default GroupFooter;
