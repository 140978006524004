import React from "react";

type Props = {
    onAddGroup: () => void,
    notFixedPosition?: boolean
}

type State = {}

class AddGroupButton extends React.Component<Props, State> {

    render() {
        let style = {};
        if (this.props.notFixedPosition) {
            style = {position: "static"}
        }
        return (
            <button
                type="button"
                className="btn btn-outline-secondary add-group-button"
                id="add-group"
                onClick={this.props.onAddGroup}
                style={style}
            >
            Add a Group
            </button>
        );
    }

}

export default AddGroupButton;
