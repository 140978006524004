import { getGoogleUser } from "./login";

function getAPIUrl() {
    if (window.location.hostname === "localhost") {
        return 'https://api.tasktrack.xyz/dev/TaskTrackerDev';
    } else if (window.location.hostname === "dev.tasktrack.xyz") {
        return 'https://api.tasktrack.xyz/dev/TaskTrackerDev';
    } else {
        return 'https://api.tasktrack.xyz/prod/TaskTracker';
    }
}


// Make a request from the body
// the given body doesn't need to define the idToken field
async function makeRequest(body) {
    if (!body.hasOwnProperty('idToken') && getGoogleUser() !== undefined) {
        let expirationTimeMillis: number = getGoogleUser().getAuthResponse().expires_at;
        let currentTimeMillis: number = Date.now();
        let bufferTimeMillis: number = 30 * 1000; // give a buffer time so requests to backend don't time out
        if (expirationTimeMillis - bufferTimeMillis < currentTimeMillis) {
            await getGoogleUser().reloadAuthResponse();
        }

        body.idToken = getGoogleUser().getAuthResponse().id_token;
    }

    return fetch(getAPIUrl(),
        {
            method: "POST",
            body: JSON.stringify(body),
            headers:{ "Content-Type": "application/json"}
        }
    ).then(response => response.json())
    .then(data => { return data; } );
}

export {getAPIUrl, makeRequest}
