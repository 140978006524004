import React from 'react';
import { makeRequest } from './requests';

type Props = {
    onClose: () => void,
    onAccountDeletion: () => void
}

type State = {}

class Settings extends React.Component<Props, State> {

    private static showUngrouped: boolean;
    private static defaultColor: string;
    private static defaultView: string;
    private static dateDelimiter: string;

    /**
     * Set the settings values.
     * This should be called once when the user first logs in
     */
     static setSettings(settingsJson) {
        Settings.showUngrouped = settingsJson.showUngrouped;
        this.defaultColor = settingsJson.defaultColor;
        this.defaultView = settingsJson.defaultView;
        this.dateDelimiter = settingsJson.dateDelimiter;
    }

    static getShowUngrouped() {
        return Settings.showUngrouped;
    }

    static getDefaultColor() {
        return Settings.defaultColor;
    }

    static getDefaultView() {
        return Settings.defaultView;
    }

    static getDateDelimiter() {
        return Settings.dateDelimiter;
    }


    static async setShowUngrouped(showUngrouped: boolean) {
        let settings = await makeRequest({action: "UpdateSettings", showUngrouped: showUngrouped});
        Settings.setSettings(settings);
    }

    static async setDefaultcolor(color: string) {
        let settings = await makeRequest({action: "UpdateSettings", defaultColor: color});
        Settings.setSettings(settings);
    }

    static async setDefaultView(defaultView: string) {
        let settings = await makeRequest({action: "UpdateSettings", defaultView: defaultView});
        Settings.setSettings(settings);
    }

    static async setDateDelimiter(dateDelimiter: string) {
        let settings = await makeRequest({action: "UpdateSettings", dateDelimiter: dateDelimiter});
        Settings.setSettings(settings);
    }

    constructor(props: Props) {
        super(props);
        this.deleteAccount = this.deleteAccount.bind(this);
    }

    async deleteAccount() {
        let confirmed = window.confirm("Are you sure you want to delete your account? This will delete all data assocaited with your account and cannot be undone.");
        if (confirmed) {
            let response = prompt("Type 'delete' in the space below to confirm deletion of your account.");
            if(response === "delete") {
                console.log("Deleting account");
                let deletionResponse = await makeRequest({action: "DeleteAccount"});
                if (deletionResponse.deleted) {
                    this.props.onAccountDeletion();
                    this.props.onClose();
                    alert("Account deleted");
                } else {
                    alert("Something went wrong deleting your account. Please try again.");
                }
            } else {
                alert("Text did not match, account not deleted.");
            }
        }
    }

    render() {
        return (
            <div id="settings-container" className="popup-container">
                <div className="settings-header-container">
                    <h3 className="settings-title">Settings</h3>
                    <button className="btn" onClick={this.props.onClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-square" viewBox="0 0 16 16">
                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path>
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                        </svg>
                    </button>
                </div>
                <table className="settings-items-table">
                    <tr>
                        <td>Default view</td>
                        <td>
                            <select id="default-view-selector"
                            onChange={
                                async (e) => {
                                    await Settings.setDefaultView(e.target.value);
                                    this.forceUpdate();
                                }
                            }
                            value={Settings.defaultView}>
                                <option value="Groups">Groups</option>
                                <option value="Weeks">Weeks</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>Default color</td>
                        <td>
                            <select id="default-color-selector"
                            onChange={
                                async (e) => {
                                    await Settings.setDefaultcolor(e.target.value);
                                    this.forceUpdate();
                                }
                            }
                            value={Settings.defaultColor}>
                                <option value="white">white</option>
                                <option value="red">red</option>
                                <option value="orange">orange</option>
                                <option value="yellow">yellow</option>
                                <option value="green">green</option>
                                <option value="blue">blue</option>
                                <option value="grey">grey</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>Show ungrouped tasks in Groups view</td>
                        <td>
                            <input
                                className="form-check-input position-static"
                                type="checkbox"
                                id="show-ungrouped-checkbox"
                                checked={Settings.showUngrouped}
                                onChange={
                                    async (e) => {
                                        await Settings.setShowUngrouped(e.target.checked);
                                        this.forceUpdate();
                                    }
                                }
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Date delimiter</td>
                        <td>
                            <select
                                value={Settings.dateDelimiter}
                                onChange={
                                    async (e) => {
                                        await Settings.setDateDelimiter(e.target.value);
                                        this.forceUpdate();
                                    }
                                }
                                >
                                <option value="/">/</option>
                                <option value="-">-</option>
                                <option value=".">.</option>
                            </select>
                        </td>
                    </tr>
                </table>
                <div id="delete-account">
                    <button className="btn btn-danger" onClick={this.deleteAccount}>Delete Account</button>
                </div>
            </div>
        );
    }
}

export { Settings };
