import React from "react";

type Props = {
    checked: boolean
    onCheck: (a: boolean) => void
}

type State = {}


class Checkbox extends React.Component<Props, State> {

    onCheck(checked: boolean) {
        this.props.onCheck(checked);
    }

    render() {
        return (
            <div className="task-checkbox">
                <input
                    className="form-check-input position-static"
                    type="checkbox"
                    checked={this.props.checked}
                    onChange={(event) => this.onCheck(event.target.checked)} />
            </div>
        )
    }

}

export default Checkbox;
