import React from "react";
import { ArchivedGroupsView } from "./archivedGroupsView";
import { DefaultGroupsView } from "./defaultGroupsView";
import Loading from "./sharedComponents/loading";
import WeeksView from "./weeksView";

type Props = {
    user: any,
    view: string
};

type State = {};

class TaskView extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        console.log(props);
    }

    render() {
        if (this.props.view ===  null) {
            // view is not set, still loading
            return (
                <Loading />
            )
        }
        console.log("View is: " + this.props.view);
        let view = this.props.view.toLowerCase();
        let viewComponent = <DefaultGroupsView />;
        if (view === "groups") {
            viewComponent = <DefaultGroupsView />;
        } else if (view === "weeks") {
            viewComponent = <WeeksView />;
        } else if (view === "archivedgroups") {
            viewComponent = <ArchivedGroupsView />;
        }
        return viewComponent;
    }

}

export default TaskView;
